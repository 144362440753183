// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-single-sanction-type-js": () => import("./../src/templates/single-sanction-type.js" /* webpackChunkName: "component---src-templates-single-sanction-type-js" */),
  "component---src-templates-single-case-js": () => import("./../src/templates/single-case.js" /* webpackChunkName: "component---src-templates-single-case-js" */),
  "component---src-templates-single-episode-js": () => import("./../src/templates/single-episode.js" /* webpackChunkName: "component---src-templates-single-episode-js" */),
  "component---src-templates-single-page-js": () => import("./../src/templates/single-page.js" /* webpackChunkName: "component---src-templates-single-page-js" */),
  "component---src-templates-single-fact-js": () => import("./../src/templates/single-fact.js" /* webpackChunkName: "component---src-templates-single-fact-js" */),
  "component---src-templates-single-checklist-js": () => import("./../src/templates/single-checklist.js" /* webpackChunkName: "component---src-templates-single-checklist-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-analogy-finder-js": () => import("./../src/pages/analogy-finder.js" /* webpackChunkName: "component---src-pages-analogy-finder-js" */),
  "component---src-pages-cases-js": () => import("./../src/pages/cases.js" /* webpackChunkName: "component---src-pages-cases-js" */),
  "component---src-pages-checklist-js": () => import("./../src/pages/checklist.js" /* webpackChunkName: "component---src-pages-checklist-js" */),
  "component---src-pages-search-js": () => import("./../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-types-of-sanctions-js": () => import("./../src/pages/types-of-sanctions.js" /* webpackChunkName: "component---src-pages-types-of-sanctions-js" */)
}


// See: https://dev.to/changoman/gatsby-js-global-state-w-react-context-usereducer-3c1

import React from 'react'

export const GlobalStateContext = React.createContext()
export const GlobalDispatchContext = React.createContext()

const initialState = {
  expandedPanels: {
    'types-of-sanctions': [],
    episodes: []
  }
}

// We need to preserve expanded panels when navigating through the app
// Do this by using global context and saving all opened panels ids to array
// Then we can check if panel is in this array and set it opened by default
function reducer(state, action) {
  switch (action.handler) {
    case 'changeExpandedPanels': {
      return modifyExpandedPanelsArr(action, state)
    }
    case 'resetExpandedPanels': {
      // Reset to the initial state
      return {
        ...state,
        initialState
      }
    }
    default:
      throw new Error('Bad Action Type')
  }
}

// Modify expanded panels array
// Also use different array according to the page type
function modifyExpandedPanelsArr(action, state) {
  const panelId = action.id
  const type = action.pageType
  const newExpandedPanels = { ...state.expandedPanels }

  const index = newExpandedPanels[type].indexOf(panelId)
  if (index > -1) {
    // Remove item from array
    newExpandedPanels[type].splice(index, 1)
  } else {
    // Add new item to array
    newExpandedPanels[type].push(panelId)
  }

  return {
    ...state,
    expandedPanels: {
      ...newExpandedPanels
    }
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>{children}</GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GlobalContextProvider

module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/zeit/53ea4da3/web/src/containers/app-layout.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"UN SanctionsApp","short_name":"UN SanctionsApp","start_url":"/","background_color":"#FFFFFF","theme_color":"#429AFA","display":"standalone","icon":"src/assets/icons/icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-164059248-1","head":false,"anonymize":true,"respectDNT":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"appendScript":"/zeit/53ea4da3/web/custom-sw-code.js","precachePages":["/types-of-sanctions","/types-of-sanctions/*","/cases","/cases/*","/cases/*/episodes/*","/facts/*","/facts","/pages","/pages/*","/checklist","/checklist/*","/checklist/**/*","/search","/analogy-finder","/404"],"workboxConfig":{"skipWaiting":false,"cleanupOutdatedCaches":true,"runtimeCaching":[{"urlPattern":{},"handler":"StaleWhileRevalidate"}]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
